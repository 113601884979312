<template>
  <div class="auth-wrapper aut-bg-img">
    <div class="auth-content signup">
      <div class="card">
        <spinner-component v-if="isCheckInProgress"></spinner-component>

        <div v-if="!isCheckInProgress && profileIsNotFinished" class="card-body">
          <div class="mb-4 text-center">
            <i class="feather icon-unlock auth-icon"></i>
          </div>

          <h3 class="mb-4 text-center">{{ $t('finishInvite') }}</h3>

          <DynamicForm
            :initial-field-value="profileData"
            :schema="formSchema"
            :button-data="submitButton"
            :is-disabled="false"
            form-class="justify-content-center"
            @submit="submit"
          >
            <template v-slot:error>
              <div
                class="server-error-message content mt-3"
                v-if="errorMessage.length"
              >
                {{ errorMessage }}
                <button
                  @click.prevent="errorMessage = ''"
                  class="feather icon-x button"
                ></button>
              </div>
            </template>
          </DynamicForm>

          <div class="row justify-content-center">
            <language-element></language-element>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, defineAsyncComponent } from 'vue'
import { AVAILABLE_LANGUAGES } from '@/application/localization/index.js'
import { useChangeLanguage } from '@/application/composables/changeLanguage.js'
import { useInviteLinkProcess } from '@/application/composables/inviteLinkProcess.js'
import DynamicForm from '../forms/DynamicForm.vue'

export default {
  name: 'InviteLandingPage',
  components: {
    DynamicForm,
    'language-element': defineAsyncComponent(() => import('@/application/components/elements/LanguageElement.vue')),
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
  },
  setup() {
    const languages = AVAILABLE_LANGUAGES
    const { changeLanguageValue } = useChangeLanguage()

    const {
      verifyInviteToken,
      isCheckInProgress,
      profileIsNotFinished,
      profileData,
      formSchema,
      submitButton,
      submit,
      errorMessage,
    } = useInviteLinkProcess()

    onMounted(() => {
      verifyInviteToken()
    })

    return {
      languages,
      changeLanguageValue,
      isCheckInProgress,
      profileIsNotFinished,
      profileData,
      formSchema,
      submitButton,
      submit,
      errorMessage,
    }
  },
}
</script>
