import { setLocale } from '@vee-validate/i18n'
import { useI18n } from 'vue-i18n'
import { AVAILABLE_LANGUAGES } from '@/application/localization'

export function useChangeLanguage() {
  const i18n = useI18n()
  const changeLanguageValue = (locale) => {
    if (AVAILABLE_LANGUAGES.includes(locale)) {
      i18n.locale.value = locale
      setLocale(locale)
      localStorage.setItem('lang', locale)
      localStorage.removeItem('default_language')
      return true
    }
    return false
  }

  const isDefaultLanguage = () => localStorage.getItem('default_language') === 'Y'

  return { changeLanguageValue, isDefaultLanguage }
}
