import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { getCountriesList } from '@/application/utils/countries'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { trimValues } from '@/application/utils/trim.js'
import { getCookie, setCookie } from '@/application/utils/cookie.js'
import { trackRegistrationComplete } from '@/application/utils/tracking-scripts'
import { getLocalizedServerError } from '@/application/utils/localization'
import { processInviteTokenApiRequest, inviteSendProfileDataApiRequest } from '@/application/services/invite.js'
import { router } from '@/application/router/index.js'

export function useInviteLinkProcess() {
  const i18n = useI18n()
  const store = useStore()
  const vueRouter = useRouter()
  const route = vueRouter.currentRoute.value
  const token = route.params.token
  const isCheckInProgress = ref(false)
  const profileIsNotFinished = ref(false)
  const profileData = ref({})
  const errorMessage = ref('')
  const isDisabled = ref(false)

  const formSchema = computed(() => ({
    email: {
      label: i18n.t('forms.checkout.email'),
      name: 'email',
      as: 'input',
      disabled: true,
      type: 'text',
      rules: 'required|email|disposable_email',
      cols: ' col-12',
      inputClass: 'form-control',
    },
    name: {
      label: i18n.t('forms.checkout.name'),
      name: 'first_name',
      as: 'input',
      type: 'text',
      rules: 'required',
      cols: ' col-12 col-sm-6',
      inputClass: 'form-control',
    },
    lastName: {
      label: i18n.t('forms.checkout.last_name'),
      name: 'last_name',
      as: 'input',
      type: 'text',
      rules: 'required',
      cols: ' col-12  col-sm-6',
      inputClass: 'form-control',
    },
    phoneNumber: {
      label: i18n.t('phoneNumber'),
      name: 'phone_number',
      type: 'text',
      as: 'input',
      rules: 'required|phone_mobile',
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
      description: i18n.t('forms.checkout.phone_description'),
    },
    country: {
      label: i18n.t('forms.checkout.country'),
      name: 'country',
      type: 'text',
      as: 'select',
      rules: 'required',
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
      children: getCountriesList().map(country => ({
        tag: 'option',
        value: country.code,
        text: country.name,
      })),
    },
    postalCode: {
      label: i18n.t('forms.checkout.postalCode'),
      name: 'postal_code',
      type: 'text',
      as: 'input',
      rules: 'required',
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
      description: i18n.t('forms.checkout.postalCode_description'),
    },
    companyName: {
      label: i18n.t('forms.checkout.companyName'),
      name: 'company_name',
      type: 'text',
      as: 'input',
      rules: '',
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
      description: i18n.t('optional'),
    },
    companyWebSite: {
      label: i18n.t('forms.checkout.companyWebSite'),
      name: 'company_website',
      type: 'text',
      as: 'input',
      rules: 'url:false',
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
      description: i18n.t('optional'),
    },
  }))
  const submitButton = computed(() => ({
    wrapper: 'justify-content-center',
    class: 'btn btn-primary shadow-2 mb-3 mt-4',
    text: i18n.t('forms.invite.submit'),
  }))
  const submit = (data) => {
    errorMessage.value = ''
    isDisabled.value = true
    const formatted = trimValues(data)
    formatted.invite_token = token

    inviteSendProfileDataApiRequest(formatted)
      .then(({ data }) => {
        const isExpired = data.expired
        const isFinished = data.profile_finished
        const registrationData = data.registration_data

        // Invite is active and profile is filled
        if (!isExpired && isFinished) {
          setCookie({ session_token: data.session_token, expire_date: data.expire_date })
          trackRegistrationComplete(registrationData)
          store.commit('changeAuthStatus', getCookie('session_token'))
          router.push({ name: 'dashboard' })
          return
        }

        // Profile is filled, but invite is expired
        if (isExpired && isFinished) {
          router.push({ name: 'sign-in', query: { email: registrationData.email } })
        }

        isDisabled.value = false
      })
      .catch(err => {
        errorMessage.value = getLocalizedServerError(i18n, 'forms.invite.errors.', err.response)
        isDisabled.value = false
      })
  }

  const verifyInviteToken = () => {
    isCheckInProgress.value = true

    processInviteTokenApiRequest({ invite_token: token })
      .then(({ data }) => {
        const isExpired = data.expired
        const isFinished = data.profile_finished
        const registrationData = data.registration_data

        // Invite is active and profile is filled
        if (!isExpired && isFinished) {
          setCookie({ session_token: data.session_token, expire_date: data.expire_date })
          trackRegistrationComplete(registrationData)
          store.commit('changeAuthStatus', getCookie('session_token'))
          router.push({ name: 'dashboard' })
          return
        }

        // Profile is filled, but invite is expired
        if (isExpired && isFinished) {
          router.push({ path: 'sign-in', query: { email: registrationData.email } })
          return
        }

        // Profile is not fully finished
        profileIsNotFinished.value = true
        profileData.value = registrationData
        isCheckInProgress.value = false
      })
      .catch((err) => {
        console.log(err)
        isCheckInProgress.value = false
      })
  }

  return {
    verifyInviteToken,
    isCheckInProgress,
    profileIsNotFinished,
    profileData,
    formSchema,
    submitButton,
    submit,
    errorMessage,
  }
}
