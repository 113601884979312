export function getCookie(name) {
  const cookiesObject = {}
  const cookies = document.cookie
  const values = cookies.split(';')
  values.forEach(item => {
    const [key, value] = item.split('=')
    cookiesObject[key.trim()] = value
  })
  return cookiesObject[name]
}

export function setCookie({ expire_date, session_token }) {
  const date = new Date(expire_date)
  const expires = date.toUTCString()
  document.cookie = `session_token=${session_token};expires=${expires};Path=/`
}

export function setCookieName(cookieName, cookieValue) {
  document.cookie = `${cookieName}=${cookieValue}`
}
